import {LOAD_USERS} from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function reducerCache(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS : {
      return {
        doors : [
          ...state.doors
        ],
        users : action.data
      }
    }
    default: {
      return state;
    }
  }
}
