export default {
  MODE: "prod",
  DEV_URL: `http://${window.location.hostname}:4444`,
  PROD_URL: `${window.location.origin}/api`,
  REQUEST_TIMEOUT : 3000 /*in miliseconds*/,
  REQUEST_TIMEOUT_ERROR_MESSAGE : "timeout_error",
  DOOR_STATUS: {
    OPEN_UNLOCK: 0,
    CLOSE_UNLOCK: 1,
    CLOSE_LOCK: 2,
    BUSY: 3,
    WAITING: 4,
    UNKNOWN: -1
  }
};
