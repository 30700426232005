import Translate from "localization/Translate"

const EN = {
  900: "Request Failed!",
  500: "Internal Server Error",
  700: "Network Error",
  701: "Timeout Error !",
  LoadingPage: {
    loadingLabel: "Loading...",
  },
  SettingsPage: {
    title: "Settings",
    updateSuccess : "Updated new settings successfully",
    updateFail : "Failed to update new settings",
    officeSettings : "Office Settings",
    doorAlertStartTime : "Door Alert Start Time",
    doorAlertEndTime : "Door Alert End Time",
    doorAlertPassword : "Door Alert Password",
    doorAlertWeekdays : "Door Alert Weekdays",
    sendWarningMessageWhenDoorIsOpenFor : "Send warning message when door is open for",
    seconds : "seconds",
    duringDoorAlertTimes : "during door alert times"
  },
  LogsTable: {
    idLabel: "Log ID",
    doorNameLabel: "Door Name",
    userLabel: "User",
    actionLabel: "Action",
    dateLabel: "Date",
    timeLabel: "Time",
    logIdentifierLabel: "Log Identifier",
    userNameLabel: "Username",
  },
  LoginPage: {
    welcome: "W e l c o m e",
    loginSuccess: "Successfuly logged in.",
    logoutSuccess: "Sucessfully logged out.",
    loginFail: "Incorrect username or password",
    permissionDenied: "Permission denied.",
    autoLoginSuccess: "You have been autotomatically logged in.",
    autoLogout: "You have been automatically logged out.",
  },
  UserSettingsPage: {
    title: "User Settings",
    addNewUser: "Add New User",
    editUser: "Edit User",
    deleteUser: "Delete User",
    firstName: "Name",
    lastName: "Lastname",
    passwordStatus: "Password Status",
    userRole: "User Role",
    select: "Select",
    mailAddress: "Mail Address",
    adminType: "Admin",
    employeeType: "Employee",
    corporateType: "Corporate",
    usersWillBeDeleted: "Users will be deleted!",
    doYouWantToComplete: "Do you want to complete",
    progress: "progress?",
    addUserSuccess: "User successfuly created.",
    addUserFail: "User could not be created !",
    deleteUserSuccess: "Deleted users successfully.",
    deleteUserFail: "Failed to delete user !",
    editUserSuccess: "Successfully edited user.",
    editUserFail: "Failed to edit user !",
    resetPassword : "Reset Password ?"
  },
  LogsPage: {
    title: "Logs",
    searchPlaceholder: "Search...",
  },
  OverviewPage: {
    title: "Overview",
    doorCardTitle: "Door",
    userCardTitle: "Users",
    adminLabel: "Admin",
    employeeLabel: "Employee",
    corporateLabel: "Corporate",
    logsCardTitle: "Log",
    door0: "Open",
    door1: "Closed / Unlocked",
    door2: "Closed / Locked",
    door3: "Busy",
    door4: "Busy",
    "door-1": "Disconnected",
  },
  SpeedDial: {
    snapshot: "Snapshot",
  },
  Buttons: {
    logout: "Logout",
    upload: "Upload",
    update : "Update",
    download: "Download",
    delete: "Delete",
    save: "Save",
    login: "Login",
    cancel: "Cancel",
    ok: "OK",
    retry: "Retry",
    revert : "Revert",
    retrieve : "Retrieve",
    reset : "Reset"
  },
  Textfield: {
    emailLabel: "Email",
    usernameLabel: "Username",
    passwordLabel: "Password",
  },
  Errors: {
    serverError: "Server encountered an error!",
    networkError: "Network Error",
    httpError: "Http request failed!",
  },
  Snackbar: {
    UserSettings: {
      changePasswordSuccess: "Password successfully changed.",
      ChangePasswordFail: {
        oldPasswordError: "Password is incorrect.",
        serverError: "Password could not change, internal server error.",
      },
      changePreferencesSuccess: "Preferences succesfully changed.",
      changePreferencesFail: "Preferences didn't change.",
    },
  },
}

const translate = new Translate(EN)

export default translate
