import { APP_ERROR, HIDE_ERROR, HIDE_SNACKBAR, CLEAR_APP_ERROR } from "redux/actions/index";
import initialState from "redux/reducers/initialState";

export default function reducerError(state = initialState, action) {
  console.log("Store will update", action)
  switch (action.type) {
    case APP_ERROR: {
      return {
        status : action.data.status,
        message : action.data.message,
        show : true
      };
    }
    case HIDE_ERROR: {
      return {
        ...state,
        show : false
      }
    }
    case HIDE_SNACKBAR: {
      return {
        ...state,
        show : false
      }
    }
    case CLEAR_APP_ERROR: {
      return {
        status : null,
        message : null,
        show : false
      }
    }
    default: {
      return state;
    }
  }
}
