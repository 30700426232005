import * as types from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_WAIT:
      return {
        ...state,
        waiting : true
      };
    case types.LOGOUT_SUCCESS:
      return { ...state, logoutSuccess: true, waiting:false, user: null };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: {...action.data},
        waiting: false
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        loginFail : true,
        permissionDenied : false,
        waiting : false
      };
    case types.PERMISSION_DENIED:
      return {
        ...state,
        permissionDenied : true,
        loginFail : false,
        waiting : false,
      }
    case types.AUTO_LOGOUT:
      return {
        ...state,
        autoLogout : true,
        user : null,
        firstRender : false
      }
    case types.APP_ERROR:
      return {...state, firstRender : false, waiting: false}
    case types.HIDE_SNACKBAR:
      return {...state, autoLogout : false, waiting: false, autoLoginSuccess : false, logoutSuccess : false}
    case types.AUTO_LOGIN_FAIL: 
      return {...state, firstRender:false, autoLoginSuccess:false, waiting : false}
    case types.AUTO_LOGIN_SUCCESS:
      return {...state, firstRender : false, autoLoginSuccess : true, user : {...action.data}, waiting:false}
    default:
      return state;
  }
}
