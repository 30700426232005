 export default {
    login : (username, password) => ({
        url : "/login",
        method : "post",
        data : {
            mail : username,
            password : password,
            adminLogin : true
        }
    }),
    logout : () => ({
        url : "/logout",
        method : "post"
    }),
    refreshToken : () => ({
        url : "/refresh_token",
        method : "post"
    }),
    getUserInfo : () => ({
        url : "/user/info",
        method : "get"
    }),
    getUsers : () => ({
        url : "/user",
        method : "get"
    }),
    addUser : (user) => ({
        url : "/user",
        method : "post",
        data : user
    }),
    editUser : (user) => ({
        url : "/user",
        method : "put",
        data : user
    }),
    deleteUser : (id) => ({
        url : "/user",
        method : "delete",
        data : { id : id }
    }),
    getLogs : (query = "") => ({
        url : "/log?" + query,
        method : "get"
    }),
    getDoors : () => ({
        url : "/door",
        method : "get"
    }),
    getDoorStatus : (id=2, officeId=1) => ({
        url : `/door_status?office_id=${officeId}&id=${id}`,
        method : "get"
    }),
    getOfficeConfig : (officeId=1) => ({
        url : `/office/config?office_id=${officeId}`,
        method : "get"
    }),
    updateOfficeConfig : (config, officeId=1) => ({
        url : `/office/config`,
        method : "post",
        data : {
            office_id : officeId,
            config : config
        }
    })
    
}