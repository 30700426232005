import React from "react";
import TokenRefresher from "components/TokenRefresher";
import { Provider } from "react-redux";
import store from "redux/store";
import SplashScreen from "views/SplashScreen";

const MasterPage = React.lazy(() => import("views/MasterPage"));

function App() {
  return (
    <React.Suspense fallback={<SplashScreen />}>
      <Provider store={store}>
        <TokenRefresher />
        <MasterPage />
      </Provider>
    </React.Suspense>
  );
}

export default App;
