import axios from "axios";
import config from "config/index";
const instance = axios.create({
  baseURL: config.MODE === "prod" ? config.PROD_URL : config.DEV_URL,
  timeout : config.REQUEST_TIMEOUT
});

/*define interceptor to send cookies in case cross site reference 
as default browsers does not send cookies to different origins.
In development mode cors is used */
if (config.MODE === "dev") {
  instance.interceptors.request.use(function(config) {
    config.withCredentials = true;
    return config;
  });

  //log every response to console in development mode
  instance.interceptors.response.use(res => {
    console.log("Server response", res);
    return res;
  });
}

export default instance;

//export {setAuthToken};
