import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        show: true
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        show: false
      };
    default:
      return {
        ...state
      };
  }
}
