import { CHANGE_LANGUAGE_MENU_TEXT } from "redux/actions/index";
import menuLang from "localization/Menu"

import initialState from "redux/reducers/initialState";

export default function reducerLanguage(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE_MENU_TEXT: {
      return {
        menu : menuLang[action.data]
      }
    }
    default: {
      return state;
    }
  }
}
