import Translate from "localization/Translate"

const TR = {
  LoginPage : {
    welcome: "H o ş g e l d i n"
  },
  Menu: {
    overview: "Overview English",
    dashboard: "Dashboard",
    license: "License",
    Profile: {
      myAccount: "My Account",
      logOut: "Log Out"
    }
  },
  SpeedDial: {
    snapshot: "Anlık Foto"
  },
  Buttons: {
    upload: "Yükle",
    download: "İndir",
    delete: "Sil",
    save: "Kaydet",
    login: "Giriş",
    cancel: "İptal",
    ok: "Tamam",
    retry: "Yeniden Dene"
  },
  Textfield: {
    emailLabel: "Elektronik Posta Adresi",
    usernameLabel: "Kullanıcı Adı",
    passwordLabel: "Şifre"
  },
  Errors: {
    serverError: "Server encountered and error!",
    httpError: "Http request failed!",
    loginFail: "Incorrect username or password",
    permissionDenied: "Permission denied."
  }
};

const translate = new Translate(TR);

export default translate;
