export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_WAIT = 'LOGIN_WAIT';
export const AUTO_LOGIN_FAIL = "AUTO_LOGIN_FAIL";
export const CHANGE_THEME="CHANGE_THEME";
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS";
export const CHANGE_LANGUAGE_MENU_TEXT="CHANGE_LANGUAGE_MENU_TEXT";
export const APP_ERROR = "APP_ERROR";
export const CLEAR_APP_ERROR = "CLEAR_APP_ERROR";
export const AUTO_LOGOUT = "AUTO_LOGOUT";
export const HIDE_ERROR = "HIDE_ERROR";
export const PERMISSION_DENIED = "PERMISSION_DENIED";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const PAGE_TRANSITION = "PAGE_TRANSITION";
export const LOAD_USERS = "LOAD_USERS";

 