import { APP_ERROR, SHOW_LOADING, HIDE_LOADING, AUTO_LOGOUT, PAGE_TRANSITION } from "redux/actions/index";
import initialState from "redux/reducers/initialState";

// TODO: reset loading to 0 after page transition
export default function redurcerLoading(state = initialState, action) {
  switch (action.type) {
    case APP_ERROR: {
      return 0
    }
    case HIDE_LOADING: {
        return state - 1
    }
    case SHOW_LOADING: {
        return state + 1
    }
    case AUTO_LOGOUT: {
      return 0
    }
    case PAGE_TRANSITION : {
      return 0
    }
    default: {
      return state;
    }
  }
}
