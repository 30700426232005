export default {
  //-- FRONTEND STATUS CODES
  RSP_TYPE_REQUEST_FAILED: 900,
  RSP_TYPE_INTERNAL_SERVER_ERROR: 500,
  RSP_TYPE_ERR_INVALID_TOKEN: 600,
  RSP_TYPE_ERR_NETWORK_ERR : 700,
  RSP_TYPE_ERR_TIMEOUT_ERR : 701,

  //-- FRONTEND STATUS MESSAGES
  RSP_MESSAGE_ERR_REQUEST_TIME_OUT : "Request Time Out Error !",
  RSP_MESSAGE_ERR_NETWORK_ERR : "Server is not available",
  RSP_MESSAGE_REQUEST_FAILED: "Network error, please try again.",
  RSP_MESSAGE_INTERNAL_SERVER_ERROR: "Server Error!",
  RSP_MESSAGE_INVALID_TOKEN: "Authentication failed.",
  RSP_MESSAGE_ERR_TIMEOUT_ERR : "Timeout Error !",

  //-- BACKEND STATUS CODES
  RSP_TYPE_SUCCESS: 100,
  RSP_TYPE_ERR_TOKEN_EXPIRED: 101,
  RSP_TYPE_ERR_TOKEN_IS_NOT_VALID: 102,
  RSP_TYPE_ERR_TOKEN_IS_NOT_GENERATED: 103,
  RSP_TYPE_ERR_TOKEN_IS_VERIFIED: 104,
  RSP_TYPE_ERR_TOKEN_IS_NOT_MATCH_WITH_USER: 105,
  RSP_TYPE_ERR_USER_NOT_FOUND: 106,
  RSP_TYPE_ERR_USER_PASSWORD_NOT_CORRECT: 107,
  RSP_TYPE_ERR_DATABASE_ERROR: 108,
  RSP_TYPE_ERR_DATABASE_CONNECTION_ERROR: 109,
  RSP_TYPE_ERR_DATABASE_QUERY_ERROR: 110,
  RSP_TYPE_ERR_DOOR_NOT_FOUND: 110,
  RSP_TYPE_ERR_DOOR_INFO_COULD_NOT_UPDATED: 111,
  RSP_TYPE_ERR_DOOR_NOT_CONNECTED: 112,
  RSP_TYPE_ERR_UNAUTHORIZED: 113,
  RSP_TYPE_ERR_INVALID_MODEL: 114,
  RSP_TYPE_ERR_INVALID_CLIENT: 114,
  RSP_TYPE_ERR_PASSWORD_CHANGE_REQUIRED: 115,
  RSP_TYPE_INCORRECT_ALERT_PASSWORD: 116,
  RSP_TYPE_DOOR_BUSY: 117,
  RSP_TYPE_ERR_UNKNOWN: 800,
  RSP_TYPE_DEV_MODE: 801,

  //-- BACKEND STATUS MESSAGES
  RSP_MESSAGE_COMPLETED: "Request Completed",
  RSP_MESSAGE_USER_NOT_FOUND: "User not found!",
  RSP_MESSAGE_WRONG_PASSWORD_OR_MAIL: "Username or password is not correct!",
  RSP_MESSAGE_TOKEN_COULDNOT_GENERATED: "Error occured when generating token!",
  RSP_MESSAGE_TOKEN_EXPIRED: "Given token is expired!",
  RSP_MESSAGE_TOKEN_IS_NOT_VALID: "Given token is not valid!",
  RSP_MESSAGE_TOKEN_IS_NOT_MATCH_WITH_USER:
    "Given token is not matched with given user!",
  RSP_MESSAGE_DOOR_OPEN_COMMAND_SENT: "Door open command was sent the doors.",
  RSP_MESSAGE_DOOR_LOCK_COMMAND_SENT: "Door lock command was sent the doors.",
  RSP_MESSAGE_DOOR_FOUND: "Door is found!",
  RSP_MESSAGE_DOOR_NOT_FOUND: "Door is not found!",
  RSP_MESSAGE_DOOR_INFO_COULD_NOT_UPDATED:
    "Door informations could not be updated!",
  RSP_MESSAGE_ERR_DOOR_NOT_CONNECTED: "Door is not connected to server!",
  RSP_MESSAGE_ERR_UNAUTHORIZED: "User does not have permission.",
  RSP_MESSAGE_ERR_INVALID_MODEL: "Given model is invalid.",
  RSP_MESSAGE_ERR_INVALID_CLIENT: "Client version is not valid.",
  RSP_MESSAGE_ERR_PASSWORD_CHANGE_REQUIRED:
    "User's password needs to be changed.",
  RSP_MESSAGE_INCORRECT_ALERT_PASSWORD: "Alert password is incorrect.",
  RSP_MESSAGE_DOOR_BUSY: "Door is busy with processing command."
};
