class Translate {
  constructor(lang) {
    this.lang = lang;
  }

  get(path) {
    if(!path) {
      return "Error: undefined parameter"
    }
    if(typeof(path) === 'number') {
      return this.lang[path];
    }
    let pathSplitted = path.split(".");
    if (pathSplitted.length === 1) {
      let result = this.lang[pathSplitted[0]];
      return (
        (typeof result === "string" && result) || "missing localization:" + path
      );
    }
    let result = this.lang;
    try {
      pathSplitted.forEach(item => {
        result = result[item];
      });
    } catch (e) {
      result = "missing locatizatin:" + path;
    }
    if (!result || typeof result !== "string") {
      return "missing locatizatin:" + path;
    }
    return result;
  }
}

export default Translate;
