import {
  login as loginCall,
  getUserInfo,
  logout as logoutCall
} from "api/index";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  PERMISSION_DENIED,
  LOGIN_WAIT,
  AUTO_LOGOUT,
  AUTO_LOGIN_FAIL,
  AUTO_LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "redux/actions";
import STATUS from "config/status_codes";

function loginFail() {
  return {
    type: LOGIN_FAIL
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}

function autoLoginFail() {
  return {
    type: AUTO_LOGIN_FAIL
  };
}

function autoLoginSuccess(data) {
  return {
    type: AUTO_LOGIN_SUCCESS,
    data: data
  };
}

function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data: data
  };
}

export function autoLogout() {
  return function(dispatch) {
    dispatch({
      type: AUTO_LOGOUT
    });
  };
}

function loginWait() {
  return {
    type: LOGIN_WAIT
  };
}

function permissionDenied() {
  return {
    type: PERMISSION_DENIED
  };
}

export function logout() {
  return function(dispatch) {
    logoutCall().then(res => {
      if (!res) return;
      if (res.data.status === STATUS.RSP_TYPE_SUCCESS) {
        dispatch(logoutSuccess());
      }
    });
  };
}

export function login(username, password) {
  return function(dispatch) {
    dispatch(loginWait());
    loginCall(username, password).then(res => {
      if (!res) return;
      if (res.data.status === STATUS.RSP_TYPE_SUCCESS) {
        dispatch(
          loginSuccess(res.data.data)
        );
      } else if (
        res.data.status === STATUS.RSP_TYPE_ERR_PASSWORD_CHANGE_REQUIRED ||
        res.data.status === STATUS.RSP_TYPE_ERR_UNAUTHORIZED
      ) {
        dispatch(permissionDenied());
      } else {
        dispatch(loginFail());
      }
    });
  };
}

export function checkLoginStatus() {
  return function(dispatch) {
    dispatch(loginWait());
    getUserInfo().then(res => {
      if (!res) dispatch(autoLoginFail());
      else if (res.data.status === STATUS.RSP_TYPE_SUCCESS) {
        const user = res.data.data;
        if(user.password_status === 1 && user.type === 1) {
          dispatch(
            autoLoginSuccess(user)
          );
        }
      } else dispatch(autoLoginFail());
    });
  };
}
