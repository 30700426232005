import React from "react";
import "assets/css/animate.css";
import { Animated } from "react-animated-css";
import DOOR from "assets/img/LoadingPage/biss_splash_screen_door.svg";
import SCREEN from "assets/img/LoadingPage/biss_splash_screen.svg";
import Grid from "@material-ui/core/Grid";

export default function Loading(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "relative",
        backgroundColor: "#008AA8"
      }}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={1500}
        animationOutDuration={1000}
        isVisible={true}
      >
        <Grid container spacing={0} direction="row" style={{ width: "60vw" }}>
          <Grid item xs={5} container justify="flex-end">
            <img src={SCREEN} alt="LOGO" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={2} container justify="center">
            <div style={{height : "65vh", width: "10px", backgroundColor:"white", zIndex : 100}}/>
          </Grid>
          <Grid item xs={5} container justify="flex-start">
            <img src={DOOR} alt="DOOR" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Animated>
    </div>
  );
}
