import { useEffect, useRef } from "react";

//react-redux related imports
import { useSelector } from "react-redux";

import {
  refreshToken,
} from "api";

export default function() {
  const user = useSelector(state => state.session.user);
  const tokenRefresher = useRef(null);

  useEffect(() => {
    if (!user) {
      console.log("Stopping tokenRefresher...");
      clearInterval(tokenRefresher.current);
    } else if (user) {
      refreshToken();
      console.log("Starting tokenRefresher...");
      tokenRefresher.current = setInterval(() => {
        console.log("Token refresher runs.");
        refreshToken();
      }, (user.token_duration - 15) * 1000);
    }
  }, [user]);

  return null;
}
