import { combineReducers } from "redux";
import sessionReducer from "redux/reducers/session";
import languageReducer from "redux/reducers/localization";
import themeReducer from "redux/reducers/theme";
import errorReducer from "redux/reducers/error";
import snackBarReducer from "redux/reducers/snackbar";
import loadingReducer from "redux/reducers/loading";
import cacheReducer from "redux/reducers/cache";

const reducers = {
    loading : loadingReducer,
    session : sessionReducer,
    language : languageReducer,
    theme : themeReducer,
    error : errorReducer,
    snackbar:snackBarReducer,
    cache : cacheReducer
 };
export default combineReducers(reducers);