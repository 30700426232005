import { APP_ERROR, HIDE_ERROR, CLEAR_APP_ERROR } from "redux/actions/index";

export function appError(data) {
  return function(dispatch) {
    dispatch({
      type: APP_ERROR,
      data: data
    });
  };
}

export function hideError() {
  return function(dispatch) {
    dispatch({
      type : HIDE_ERROR
    })
  }
}

export function clearError() {
  return function(dispatch) {
    dispatch({
      type : CLEAR_APP_ERROR
    })
  }
}
