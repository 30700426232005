import axios from "./axios";
import endpoints from "./endpoints";
import STATUS from "config/status_codes";
import appConfig from "config/index";
import store from "redux/store";
import { appError, clearError } from "redux/actions/error";
import { autoLogout } from "redux/actions/session";

var errorState = false;

export function login(username, password) {
  const config = endpoints.login(username, password);
  return sendRequest(config);
}

export function logout() {
  return sendRequest(endpoints.logout());
}

export function refreshToken() {
  return sendRequest(endpoints.refreshToken());
}

export function getUserInfo() {
  return sendRequest(endpoints.getUserInfo());
}

export function getUsers() {
  return sendRequest(endpoints.getUsers());
}

export function addUser(user) {
  return sendRequest(endpoints.addUser(user));
}

export function editUser(user) {
  return sendRequest(endpoints.editUser(user));
}

export function deleteUser(id) {
  return sendRequest(endpoints.deleteUser(id));
}

export function getLogs(query) {
  return sendRequest(endpoints.getLogs(query));
}

export function getDoors() {
  return sendRequest(endpoints.getDoors());
}

export function getDoorStatus(id, office_id) {
  return sendRequest(endpoints.getDoorStatus(id, office_id));
}

export function getOfficeConfig(office_id) {
  return sendRequest(endpoints.getOfficeConfig(office_id));
}

export function updateOfficeConfig(config, office_id) {
  return sendRequest(endpoints.updateOfficeConfig(config, office_id));
}

function dispatchClearErrorToStore() {
  store.dispatch(clearError());
}

function dispatchErrorToStore(data) {
  store.dispatch(appError(data));
}

function dispatchAutoLogout() {
  store.dispatch(autoLogout());
}

function sendRequest(config) {
  return axios(config)
    .then(res => {
      const status = res && res.data && res.data.status;
      if (!res || !res.data) {
        console.log("Error: Http request failed!");
        errorState = true;
        dispatchErrorToStore({
          message: STATUS.RSP_MESSAGE_REQUEST_FAILED,
          status: STATUS.RSP_TYPE_REQUEST_FAILED
        });
      } else if (
        status === STATUS.RSP_TYPE_ERR_UNKNOWN ||
        status === STATUS.RSP_TYPE_ERR_DATABASE_ERROR ||
        status === STATUS.RSP_TYPE_ERR_DATABASE_CONNECTION_ERROR ||
        status === STATUS.RSP_TYPE_ERR_DATABASE_QUERY_ERROR ||
        status === STATUS.RSP_TYPE_ERR_TOKEN_IS_NOT_GENERATED
      ) {
        errorState = true;
        console.log("Internal Server Error:", res.data.data);
        dispatchErrorToStore({
          message: STATUS.RSP_MESSAGE_INTERNAL_SERVER_ERROR,
          status: STATUS.RSP_TYPE_INTERNAL_SERVER_ERROR
        });
      } else if (
        status === STATUS.RSP_TYPE_ERR_TOKEN_EXPIRED ||
        status === STATUS.RSP_TYPE_ERR_TOKEN_IS_NOT_VALID ||
        status === STATUS.RSP_TYPE_ERR_TOKEN_IS_NOT_MATCH_WITH_USER
      ) {
        console.log("Token is expired, login required.");
        if (config.url !== "/user/info") {
          dispatchAutoLogout();
        }
      } else {
        if (errorState) {
          errorState = false;
          dispatchClearErrorToStore();
        }

        return res;
      }
    })
    .catch(error => {
      if (error.message === "Network Error") {
        console.log("network error");
        errorState = true;
        dispatchErrorToStore({
          message: STATUS.RSP_RSP_MESSAGE_ERR_NETWORK_ERR,
          status: STATUS.RSP_TYPE_ERR_NETWORK_ERR
        });
      } else if (error.message === appConfig.REQUEST_TIMEOUT_ERROR_MESSAGE) {
        errorState = true;
        dispatchErrorToStore({
          message: STATUS.RSP_MESSAGE_ERR_TIMEOUT_ERR,
          status: STATUS.RSP_TYPE_ERR_TIMEOUT_ERR
        });
      } else {
        console.log("Unhandled http error:", error);
        Promise.reject(error);
      }
    });
}
