import en from "localization/Menu/en";

export default {
  loading : 0,
  session : {
    autoLogout : false,
    autoLoginSuccess : false,
    permissionDenied : false,
    user : null, // if defined => user logged in
    loginFail : false,
    logoutSuccess : false,
    firstRender : true,
    waiting : false
  },
  theme : "light",
  language: {
    menu: en
  },
  error : {
    show : false,
    status : null,
    message : null
  },
  snackbar:{
    message : null,
    messageType : null,
    show:false
  },
  cache : {
    doors : [],
    users : []
  }
};
