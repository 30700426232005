import Translate from "localization/Translate"

const DE = {
  LoginPage : {
    welcome: "W i l c o m m e n"
  },
  Menu: {
    overview: "Overview English",
    dashboard: "Dashboard",
    license: "License",
    Profile: {
      myAccount: "My Account",
      logOut: "Log Out"
    }
  },
  SpeedDial: {
    snapshot: "Znapjöt"
  },
  Buttons: {
    upload: "Upload",
    download: "Download",
    delete: "Delete",
    save: "Save",
    login: "Zuckerberg",
    cancel: "Cancel",
    ok: "OK",
    retry: "Retry"
  },
  Textfield: {
    emailLabel: "Dein Email",
    usernameLabel: "Username",
    passwordLabel: "Pazzwürd"
  },
  Errors: {
    serverError: "Server encountered and error!",
    httpError: "Http request failed!",
    loginFail: "Incorrect username or password",
    permissionDenied: "Permission denied."
  }
};

const translate = new Translate(DE);

export default translate;
